function slideout_menu(){
  var slideout = new Slideout({
    'panel': document.getElementById('js-so_panel'),
    'menu': document.getElementById('js-so_menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so_toggle, #js-so_panel');
  var node = Array.prototype.slice.call(nodeList,0);
  node.forEach(function(elem, index){
    elem.addEventListener('click', function() {
      slideout.toggle();
      show_language_content(false);
    });
  });
}
let idx_lineart01,co_lineart01,abt_lineart01,abt_lineart02,abt_lineart03,rec_lineart01;
$(function() {
/*********************************************************************
  COMMON
*********************************************************************/
  slideout_menu();
  $('.js-ta_justify').each(function(){
    var new_html="";
    $(this).addClass('c-ta_justify').html().split('').forEach(function(w){
      new_html+="<span>"+w+"</span>";
    });
    $(this).html(new_html);
  });

  $('.js-aco_trg').on('click',function(){
    $(this).toggleClass('is-act').next().slideToggle();
  });
  $('.js-editor table').each(function(){
    $(this).wrap('<div class="c-tbl_responsive"><div class="rwd_sheet"></div></div>');
  });
/*********************************************************************
  INDEX
*********************************************************************/
  var key_slide=function(){
    $('.js-key_slide').on('init',function(){
      $('.slick-current').addClass('is-anime');
    }).slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      speed: 1000,
      autoplaySpeed: 4000,
      autoplay: true,
      draggable: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      swipe: true,
    }).on({
      beforeChange: function(event, slick, currentSlide, nextSlide){
        $(".slick-slide", this).eq(currentSlide).addClass('is-prev');
        $(".slick-slide", this).eq(nextSlide).addClass('is-anime');
      },
      afterChange: function() {
        $(".is-prev", this).removeClass("is-prev is-anime");
      }
    });
  }
  if(document.getElementById('js-loader') == null){
    key_slide();
  }else{
    $('#js-loader').on('animated',function(){
      key_slide();
    });
  }
  if($('.l-main').hasClass('p-index')){
    idx_lineart01 = new Vivus('js-lineart01',{
      type: 'oneByOne',
      start: 'manual',
      forceRender:false,
      duration: 200,
      animTimingFunction: Vivus.LINEAR,
    });
  }
  $()
/*********************************************************************
  COMPANY
*********************************************************************/
  if($('.l-main').hasClass('p-company')){
    co_lineart01 = new Vivus('js-lineart02',{
      type: 'oneByOne',
      start: 'manual',
      forceRender:false,
      duration: 200,
      animTimingFunction: Vivus.LINEAR,
    });
  }
/*********************************************************************
  ABOUT
*********************************************************************/
  if($('.l-main').hasClass('p-about')){
    abt_lineart01 = new Vivus('js-lineart03',{
      type: 'oneByOne',
      start: 'manual',
      forceRender:false,
      duration: 200,
      animTimingFunction: Vivus.LINEAR,
    });
    abt_lineart02 = new Vivus('js-lineart04',{
      type: 'oneByOne',
      start: 'manual',
      forceRender:false,
      duration: 200,
      animTimingFunction: Vivus.LINEAR,
    });
    abt_lineart03 = new Vivus('js-lineart05',{
        type: 'oneByOne',
        start: 'manual',
        forceRender:false,
        duration: 200,
        animTimingFunction: Vivus.LINEAR,
      },
      function (obj){
        $('#js-lineart05').parent().addClass('is-anime');
      }
    );
  }
/*********************************************************************
  RECRUIT
*********************************************************************/
  if($('.l-main').hasClass('p-recruit')){
      rec_lineart01 = new Vivus('js-lineart06',{
        type: 'sync',
        start: 'manual',
        forceRender:false,
        duration: 200,
        animTimingFunction: Vivus.LINEAR,
      },
      function (obj){
        $('#js-lineart06').parent().addClass('is-anime');
      }
    );
  }
});

$(window).on('load',function(){
  if($('.l-main').hasClass('p-index')){
    animation('index01');
  }

  if($('.l-main').hasClass('p-company')){
    animation('company01');
  }

  if($('.l-main').hasClass('p-about')){
    animation('about01');
    animation('about02');
    animation('about03');
  }

  if($('.l-main').hasClass('p-recruit')){
    animation('recruit01');
  }
});
$(window).on('scroll',function(){
  if($('.l-main').hasClass('p-index')){
    animation('index01');
  }

  if($('.l-main').hasClass('p-company')){
    animation('company01');
  }

  if($('.l-main').hasClass('p-about')){
    animation('about01');
    animation('about02');
    animation('about03');
  }

  if($('.l-main').hasClass('p-recruit')){
    animation('recruit01');
  }
});
$(window).on('resize',function(){
  winWidth = $(window).width();
});


function animation(name){
  switch(name){
    case 'index01' :
      if($('#js-lineart01').closest('.js-anime').hasClass('is-anime')){
        idx_lineart01.play(1);
      }
    break;

    case 'company01':
      if($('#js-lineart02').closest('.js-anime').hasClass('is-anime')){
        co_lineart01.play(1);
      }
    break;

    case 'about01' :
      if($('#js-lineart03').closest('.js-anime').hasClass('is-anime')){
        abt_lineart01.play(1);
      }
    break;

    case 'about02':
      if($('#js-lineart04').closest('.js-anime').hasClass('is-anime')){
        abt_lineart02.play(1);
      }
    break;

    case 'about03':
      if($('#js-lineart05').closest('.js-anime').hasClass('is-anime')){
        $('#js-lineart05').closest('.js-anime').find('.js-fade01').fadeIn(200,function(){
          $(this).siblings('.js-fade02').fadeIn(200,function(){
            $(this).siblings('.js-fade03').fadeIn(200,function(){
              abt_lineart03.play(1);
            });
          });
        });
      }
    break;

    case 'recruit01' :
      if($('#js-lineart06').closest('.js-anime').hasClass('is-anime')){
        rec_lineart01.play(1);
      }
    break;

  }
}
